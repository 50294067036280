<template>
  <div :class="['usAbout', {'bgImg':datas.bgImg}]">
    <div class="title langEnB ar"><span>{{ langAboutUs.aboutUs }}</span></div>
    <div class="content ar">
      <p 
        v-for="(item,index) in langAboutUs.text"
        :key="'aboutUs_'+index">
        {{ item }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return{

    }
  },
  props: {
    "datas":{
      default: {},
    }
  },
  computed: {
    ...Vuex.mapGetters([
      "langAboutUs",
      "screen",
    ]),
  }
}
</script>
<style lang="stylus" scoped>
.bgImg{
  background-image: url(../../assets/img/company.png);
  background-image: 100%;
  color: $Cf;
  position: relative;
  &:before{
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0,.6);  
    z-index: 0;
  }
}
.title{
  position: relative;
  font-size: $Ftm1;
  text-align: center;
  margin: .86rem auto .36rem;
  box-sizing: border-box;
  width: 100%;
  max-width: 12.3rem;
}
.content{
  font-size: $Fts3;
  max-width: 12.3rem;
  margin: .3rem auto .55rem;
  position: relative;
  z-index: 2;
  box-sizing: border-box;
  padding: 0 .2rem .1rem;
  & p{
    width: 100%;
    line-height: .27rem;
    margin-bottom: .27rem;
  }
}


@media screen and (max-width: 980px){
  p {
    font-size: $Fts4;
    text-align: center;
  }
}
</style>