<template>
  <div class="company">
    <div class="imgContent" :style="{backgroundImage:'url('+ bannerFn('bg') +')'}">
      <img :src="bannerFn()" v-show="screen">
      <video muted id="_video" loop="loop" autoplay="autoplay" @canplaythrough="canplaythroughFn" preload="load" v-show="videoReady && companyImg.video" v-if="!screen">
        <source :src="companyImg.video" type="video/mp4">
      </video>
      <div class="slogan">
        <img src="../../assets/img/slogan.png" alt="">
      </div>
    </div>
    <i-us-about class="_content"></i-us-about>
    <i-main-share></i-main-share>
  </div>
</template>
<script>
import IUsAbout from "@/components/us/about.vue"
import IMainShare from "@/components/share/main.vue"
export default {
  data(){
    return {
      videoReady: false
    }
  },
  components: {
    IUsAbout,
    IMainShare
  },
  computed: {
    ...Vuex.mapGetters([
      "companyImg",
      "screen"
    ]),
  },
  mounted(){
    console.log(this.companyImg);
  },
  methods:{
    canplaythroughFn(){
      setTimeout(()=>{
        this.videoReady = true;
      },2000);
    },
    bannerFn(bg){
      if(this.screen){
        let banner = this.companyImg.banner;
        banner = banner.slice(0, banner.length - 4) + '2' + banner.slice(-4);
        return banner;
      }else{
        return this.companyImg.banner;
      }
      
    }
  }

}
</script>
<style lang="stylus" scoped>
.company {
  & .imgContent{
    height: 6.56rem;
    overflow: hidden;  
    position: relative;
    & .slogan{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,.4);  
      flex(row,nowrap,center,center);
      & img{
        width: 30%;
        min-width: 2rem;
      }
    }
    & > img,video{
      min-width: 100%;
      min-height: 6.56rem;
      margin: 0 auto;
    }
  } 
  & img{
    // tranform(rotateY(180deg))  
  }
}
._content{
  margin: .5rem 0 .2rem;  
}
@media screen and (max-width: 980px){
  .company {
    & .imgContent{
      height: auto;
      & > img{
        width: 100%;
        height: auto;
        min-height: auto;
      }    
      & .slogan{
        & img{
          width: 60%;
        }
      }
    }
  }
}

</style>